import React from "react";
import { InstaItem } from "./InstaItem.tsx";

interface InstaGridProps {
    items: InstaItem[];
}

const gridStyle: React.CSSProperties = {
    width: "80%",
    display: "block",
    marginLeft: "10%",
};

const itemStyle: React.CSSProperties = {
    display: "block",
    objectFit: "cover",
    width: "100%",
    height: "100%",    
    borderRadius: "10px",
};

const cardStyle: React.CSSProperties = {
    //backgroundColor: "#fff",
    backgroundColor: "#bb77bb61",
    marginBottom: "7%",
    borderRadius: "10px",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
};

const captionStyle: React.CSSProperties = {
    paddingTop: "3%",
    paddingLeft: "3%",
    paddingBottom: "5%",
};

const InstaGrid = ({ items }: InstaGridProps) => {
    if(items.length === 0) {
        return (<p>No items to display</p>);
    }
    return (
        <div style={gridStyle}>
            {items.map((item) => (
                <div style={cardStyle}>
                    <a href={item.permalink} target="_blank" rel="noreferrer">
                        <img src={item.mediaUrl} key={item.mediaUrl} style={itemStyle} alt="Instagram post" />
                        <p style={captionStyle}>{item.caption}</p>
                    </a>                    
                </div>
            ))}
        </div>
    );
};

export default InstaGrid;