import React from "react";
import './mobileHeader.css';
import Logo_transparent from '../img/Logo_transparent.png';
import {Helmet} from "react-helmet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';


export default function MobileHeader() {
    return (
        <>
            <Helmet>
                <title>Vitoria Store</title>
                <script src="https://kit.fontawesome.com/ca3d167313.js" crossOrigin="anonymous"></script>
            </Helmet>
            <div className="navbar navbar-background">
                <img src={Logo_transparent} alt="Vitoria Store logo" className="mobile-header-logo"></img>
                <div className="social-container">
                    <a href="https://www.instagram.com/victory.store.sis/?hl=en" target="_blank" rel="noreferrer"><p className="social-link" style={{marginBottom: 10 + "px"}}><FontAwesomeIcon icon={faInstagram} />: Victory.Store.Sis</p></a>
                    <a href="https://www.tiktok.com/@victory.store.sis"  target="_blank" rel="noreferrer"><p className="social-link"><FontAwesomeIcon icon={faTiktok} />: Victory.Store.Sis</p></a>
                </div>
            </div>
        </>
    );
}