import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import MediaQuery from 'react-responsive';
import './index.css';
import InstaFeed from './InstaFeed/InstaFeed.tsx';
import MobileHeader from './mobileheader/mobileHeader.tsx';
import ComingSoon from './comingSoon/comingSoon.tsx';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
  <>   
    <MediaQuery maxWidth={767}>
      <MobileHeader/>
      <div className="flex justify-center items-center">
        <p className="text-2xl my-5">Browse our selection:</p>
      </div>
      <InstaFeed />
    </MediaQuery>
    <MediaQuery minWidth={768}>   
      <ComingSoon />  
    </MediaQuery>
  </>//</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
