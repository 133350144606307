import React from "react";
import { useEffect, useState, useRef } from "react";
import InstaGrid from "./InstaGrid.tsx";
import { InstaItem } from "./InstaItem.tsx";

const InstaFeed = () => {
    const [instaItems, setInstaItems] = useState<InstaItem[]>([]);

    const userIdRef = useRef('');
    const accessTokenRef = useRef('');
    const instaUrlRef = useRef('');
  
    userIdRef.current = "25448411538107473";
    accessTokenRef.current = "IGQWRNQzc0T1lrdmlTWDRWVS1EU3E4WDgzT3BNTWdKQkdSNmY3cEwtR3dKM2sxa0loYmtVV2c1cms1bWxoaGJQRURybUpVb3lLWkVaRzRKYWduQ0F0cFZAsLUczU1JRMTZACdGY0dG1ZAZAG9SS05jUEs2Y0tYQ0htcDQZD";
    instaUrlRef.current = `https://graph.instagram.com/${userIdRef.current}/media?access_token=${accessTokenRef.current}`;

    useEffect(() => {
        const userId = userIdRef.current;
        const accessToken = accessTokenRef.current;
        const instaUrl = instaUrlRef.current;

        const fetchMedia = async (id: string, instaItems: Array<InstaItem>) => {
            const mediaUrl = `https://graph.instagram.com/${id}?access_token=${accessToken}&fields=media_type,media_url,thumbnail_url,permalink,caption`;

            const res = await fetch(mediaUrl);
            const json = (await res.json());

            const instaItem: InstaItem = {
                id: json.id,
                mediaType: json.media_type,
                permalink: json.permalink,
                mediaUrl: json.media_type === "VIDEO" ? json.thumbnail_url : json.media_url,
                caption: json.caption,
            };

            setInstaItems(prevItems => [...prevItems, instaItem]);
                        
            return instaItem;
            
        }

        const doFetch = async () => {
            if(!userId || !accessToken) {
                console.log("userId or accessToken is undefined: ", {userId, accessToken});
                return;
            }
            const res = await fetch(instaUrl);
            console.log('about to get json');
            const json = (await res.json()).data;
            
            const fetchedItems: InstaItem[] = [];

            for(let i=0; i < json.length; i++) {
                const item = json[i];
                const itemId = item.id;

                const instaItem = await fetchMedia(itemId, fetchedItems);
                fetchedItems.push(instaItem);

                // This is how we can filter out posts with a specific hashtag
                //if(instaItem.caption.indexOf("#online") === -1)  {
                //if(instaItem.mediaType !== "VIDEO") {
                    //fetchedItems.push(instaItem);
                //}
                //if(instaItem.mediaType === "VIDEO") {
                //    console.log("Video post detected, skipping");
                //}
                    
            }
            
        };

        doFetch();            
    }, []);

    return (
        <InstaGrid items={instaItems} />
    );
}

export default InstaFeed;