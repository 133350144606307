import React from "react";
import './comingSoon.css';

export default function ComingSoon() {
    return (
        <div className="text-center-vertical-offset">
            <p style={{fontSize: 2.25 + "rem", lineHeight: 2.5 + "rem"}}>Coming Soon</p>
            <p style={{fontSize: 2.25 + "rem", lineHeight: 2.5 + "rem"}}>Check back later for updates!</p>
            <br/>
            <a href="https://www.instagram.com/victory.store.sis/" target="_blank" rel="noreferrer" style={{fontSize: 2.25 + "rem", lineHeight: 2.5 + "rem", textDecoration: "underline"}}>Follow Victory Store on Instagram!</a>
        </div>
    );
}